
/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
(function($) {
    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages
            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function() {
                // JavaScript to be fired on the home page
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function() {
                // JavaScript to be fired on the about us page
            }
        }
    };
    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';
            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');
            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });
            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };
    // Load Events
    $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
// Scroll to Anchor
$(function() {
    $('a[href*="#"]:not([href="#"])').click(function() {
        if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                $('html, body').animate({
                    scrollTop: target.offset().top
                }, 1000);
                return false;
            }
        }
    });
});


// Change nav color
$(document).ready(function() {
    var scroll_start = 0;
    var startchange = $('#startchange');
    var offset = startchange.offset();
    if (startchange.length) {

        // Add scrolling classes
        $(document).scroll(function() {
            scroll_start = $(this).scrollTop();
            if (scroll_start > offset.top) {
                // $("#menu").css('background-color', '#f0f0f0');
                $("#main-menu").addClass('start-scroll');

            } else {
                // $('#menu').css('background-color', 'rgb(34, 34, 34)');
                $("#main-menu").removeClass('start-scroll');
            }
        });
    }
});





function createCookie(name,value,days) {
  if (days) {
    var date = new Date();
    date.setTime(date.getTime()+(days*24*60*60*1000));
    var expires = "; expires="+date.toGMTString();
  } else {
    var expires = "";
  }
  document.cookie = name+"="+value+expires+"; path=/";
}

function setNewTab(tid) {
  createCookie('setnewtab', "http://home.brandthunder.com/" + tid + "/", 1);
  if (!("myweb" in window)) {
    if ("chrome" in window) {
      chrome.webstore.install();
    } else if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
      var params = {
        "My Web New Tab": {
          URL: "https://addons.mozilla.org/firefox/downloads/latest/687563/addon-687563-latest.xpi?src=myweb_gallery",
          toString: function () { return this.url; }
        }
      };
      InstallTrigger.install(params);
    }
  }
}




function createCookie(name,value,days) {
  if (days) {
    var date = new Date();
    date.setTime(date.getTime()+(days*24*60*60*1000));
    var expires = "; expires="+date.toGMTString();
  } else {
    var expires = "";
  }
  document.cookie = name+"="+value+expires+"; path=/";
}

function setNewTab(tid) {
  createCookie('setnewtab', "http://home.brandthunder.com/" + tid + "/", 1);
  if (!("myweb" in window)) {
    if ("chrome" in window) {
      chrome.webstore.install();
    } else if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
      var params = {
        "My Web New Tab": {
          URL: "https://addons.mozilla.org/firefox/downloads/latest/687563/addon-687563-latest.xpi?src=myweb_gallery",
          toString: function () { return this.url; }
        }
      };
      InstallTrigger.install(params);
    }
  }
}
function showPersonas(category) {
  var personas = document.querySelectorAll(".persona");
  document.getElementById("noresults").style.display = "";
  document.getElementById("search").value = "";
  document.body.removeAttribute("recommended");
  for (var i=0; i < personas.length; i++) {
    personas[i].hidden = true;
    switch (category) {
      case "all":
        personas[i].hidden = false;
        continue;
      case "recommended":
        if (personas[i].hasAttribute("data-recommended")) {
          personas[i].hidden = false;
        }
        document.body.setAttribute("recommended", "true");
        continue;
      case "new":
        if (personas[i].hasAttribute("data-new")) {
          personas[i].hidden = false;
        }
        continue;
      case "popular":
        if (personas[i].hasAttribute("data-popular")) {
          personas[i].hidden = false;
        }
        continue;
      default:
      var categories = personas[i].getAttribute("data-category").split(",");
      for (var j=0; j < categories.length; j++) {
        if (categories[j] == category) {
          personas[i].hidden = false;
        }
      }
    }
  }
}

function searchPersonas(search) {
  var personas = document.querySelectorAll(".persona");
  document.getElementById("noresults").style.display = "";
  var foundResult = false;
  var searchTerms = search.replace("*", "").split(" ");
  for (var i=0; i < personas.length; i++) {
    personas[i].hidden = true;
    var caption = personas[i].querySelector("figcaption").textContent;
    var matches = 0;
    for (var j=0; j < searchTerms.length; j++) {
      if (caption.toLowerCase().indexOf(searchTerms[j].toLowerCase()) != -1 ||
          personas[i].id.toLowerCase().indexOf(searchTerms[j].toLowerCase()) != -1) {
        matches++;
      }
    }
    if (matches == searchTerms.length) {
      personas[i].hidden = false;
      foundResult = true;
    }
  }
  if (!foundResult) {
    document.getElementById("noresults").style.display = "block";
  }
}

$(document).ready(function() {
  $(document).bind("keydown.cbox_close", function (e) {
    if (e.keyCode === 27) {
      e.preventDefault();
   }});
  // $.fn.colorbox({
  //   onLoad: function() {
  //      $('#cboxClose').remove();
  //   },
  //   scrolling: false,
  //   inline:true,
  //   overlayClose: false,
  //   showClose: false,
  //   escKey: false,
  //   href:"#lightbox-content"
  // });
  $('#lightbox-content').click(function() {
    if ("chrome" in window) {
      if ("webstore" in window.chrome) {
        /* Chrome */
        ga('send', 'event', 'MyWeb Install', 'Click', 'chrome');
        chrome.webstore.install("https://chrome.google.com/webstore/detail/cnbiadnhebmicjcbpgajglfemclnlagh", function(e) {
          ga('send', 'event', 'MyWeb Install', 'Install', 'chrome');
        }, function(e) {
          ga('send', 'event', 'MyWeb Install', 'Cancel', 'chrome');
        } );
      } else {
        /* Edge */
      }
    } else if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
      /* Firefox */
      var params = {
        "MyWeb New Tab": {
          URL: "https://addons.mozilla.org/firefox/downloads/latest/687563/addon-687563-latest.xpi?src=myweb_ad",
          toString: function () { return this.URL; }
        }
      };
      ga('send', 'event', 'MyWeb Install', 'Click', 'firefox');
      InstallTrigger.install(params);
    } else if ((navigator.userAgent.indexOf("MSIE") > -1 || navigator.userAgent.indexOf("Trident") > -1)) {
      /* IE */
    } else if (navigator.userAgent.indexOf("Safari") > -1) {
      /* Safari */
    }
  })
});


$(document).ready(function($) {
  $('#reveal').click(function(event) {
    $('#hidden-description').toggleClass('hidden');
    $('#reveal').removeClass('glyphicon-chevron-down');
    $('#reveal').addClass('glyphicon-chevron-up');
  });
});